<template>
  <div class="bg">
    <van-tabs v-model="active" sticky animated class="mytab">
      <van-tab title="我的急配">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <van-cell
              :title="item + '急需 xxx 靶向药品'"
              is-link
              v-for="item in list"
              :key="item"
              class="mylist"
              @click="goinfo"
            />
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="已过期">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <van-cell
              :title="item + '急需 xxx 靶向药品'"
              is-link
              v-for="item in list"
              :key="item"
              class="mylist"
              @click="goinfo"
            />
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: "",
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  mounted() {},
  methods: {
    // 详情
    goinfo() {
      this.$router.push({ name: "hitdistributioninfo" });
    },
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }

        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        this.loading = false;

        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
};
</script>
<style scoped>
/deep/.mytab .van-tab--active .van-tab__text {
  /* color: rgba(255, 255, 255, 1); */
}
/deep/.mytab .van-tab--active .van-tab {
  border: none;
  box-sizing: border-box;
}

/deep/.mytab .van-tab__text {
  z-index: 10;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
}
/deep/.mytab .van-tabs__line {
  width: 80px;
  height: 6px;
  background: #1daefc;
}
</style>
<style lang="less" scoped>
.mylist {
  text-align: left;
}
.mytab {
  width: 375px;
  height: 48px;
  box-sizing: border-box;
  .van-tab--active {
    .van-tab__text {
      color: rgba(255, 255, 255, 1);
    }
    .van-tab {
      border: none;
      box-sizing: border-box;
    }
  }
  .van-tab {
    // border: 1px solid #d8d8d8;
    box-sizing: border-box;
  }
  .van-tab__text {
    z-index: 10;
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
  }
  .van-tabs__line {
    width: 50%;
    height: 100%;
    background: #1daefc;
  }
}
</style>
